import React from "react"
import { Link, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"

import "katex/dist/katex.min.css"

const BlogPostTemplate = ({ data, location }) => {
  const post = data.mdx
  const siteTitle = data.site.siteMetadata.title

  let comic = ""
  if (post.frontmatter.comic) {
    comic = "comic"
  }

  let meta = []
  if (!post.published) {
    meta.push({
      name: `robots`,
      content: `noindex`,
    })
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        meta={meta}
      />
      <article className={`note-contents ${comic}`}>
        <header>
          <h1
            style={{
              marginBottom: rhythm(1 / 3),
            }}
          >
            {post.frontmatter.title}
          </h1>
          <p
            className="note-subheading"
            style={{
              ...scale(-1 / 5),
              display: `block`,
              marginBottom: rhythm(1),
            }}
          >
            <span>
              <Link to="/">Nicolae Vartolomei</Link>
            </span>
            <> &middot; </>
            <span>{post.frontmatter.date}</span>
            {post.frontmatter.updated && (
              <span>{`, updated on ${post.frontmatter.updated}`}</span>
            )}
          </p>
          {post.frontmatter.draft && (
            <p>
              <strong>
                This post is a work in progress. Please don't share.
              </strong>
            </p>
          )}
        </header>
        <MDXRenderer>{post.body}</MDXRenderer>
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
      </article>

      <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            <Link to={"/"}>Nicolae Vartolomei</Link>
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      body
      excerpt
      published
      frontmatter {
        title
        date(formatString: "YYYY/MM")
        updated(formatString: "YYYY/MM")
        starred
        draft
        comic
        importance
      }
    }
  }
`
