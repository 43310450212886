import Typography from "typography"
import BaseTheme from "typography-theme-us-web-design-standards"

BaseTheme.overrideThemeStyles = () => {
  return {
    body: {
      background: "#fcfbf6",
    },
  }
}

delete BaseTheme.googleFonts

BaseTheme.bodyColor = "#030308"
BaseTheme.headerFontFamily = ["Georgia", "serif"]
BaseTheme.bodyFontFamily = ["Georgia", "serif"]

// https://grtcalculator.com/
BaseTheme.baseFontSize = "18px"
BaseTheme.baseLineHeight = 31 / 18

const typography = new Typography(BaseTheme)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
